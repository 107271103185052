import loadable from "@loadable/component"
import { navigate } from "gatsby"
import $ from "jquery"
import React, { useState } from "react"
import useAreaMutiSelect from "../SearchResults/PredictiveSearch/useAreaMutiSelect"
import "./SearchBox.scss"
import { useLocation } from "@react-hooks-library/core"
import _ from "lodash"
import propertyType from "../../search_config/property_category.json"
import propertyType1 from "../../search_config/property_category1.json"

import SearchBoxFilter from "../FilterSearch/SearchBoxFilter"
import MultiSelectInput from "../MultiSelectInput/MultiSelectInput"
import { customStyles } from "../SelectDropdownStyle/SelectDropdownStyle"
import { usePropertyStore } from "../SearchResults/store/propertyStore"
import { useOffPlanAreaStore } from "../FilterSearch/offplanareastore"
import Select from "react-select"

const SearchBox = () => {
  const location = useLocation()
  const pathname = location?.pathname
  const [searchStateToUrl, setSearchStateToUrl] = useState()

  // const { isMobile } = useDeviceMedia()

  const {
    selectedAreas,
    areasSuggestionList,
    handleSelectArea,
    handleRemoveArea,
    handleRemoveAllAreas,
  } = useAreaMutiSelect()

  const searchText = usePropertyStore(state => state.searchText)
  const searchName = useOffPlanAreaStore(state => state.searchName)

  


  const [type, setType] = useState("sale")
  const [propertycat, setPropertyType] = useState({
    value: "residential",
    label: "Residential",
  })
  const onSearch = () => {

    if(typeof window!=="undefined"&&selectedAreas?.length>0){
      sessionStorage.removeItem("isfreetext")
    }
    else{
      if(searchName){
        const findPipe=searchName?.includes("|");
        if(typeof window!=="undefined"){
        sessionStorage.setItem("isfreetextValue", findPipe)
        }
      }
      sessionStorage.setItem("isfreetext",true)
    }
    let url
    if (propertycat?.value === "residential") {
      if (type === "sale") {
        url = "/properties/for-sale/"
      } else if (type === "rent") {
        url = "/properties/to-rent/"
      }
    } else if (propertycat?.value === "commercial") {
      if (type === "sale") {
        url = "/commercial-properties/for-sale/"
      } else if (type === "rent") {
        url = "/commercial-properties/to-rent/"
      }
    } else if (propertycat?.value === "offplan") {
      if (type === "sale") {
        url = "/off-plan/properties/for-sale/"
      } else if (type === "rent") {
        url = "/off-plan/properties/to-rent/"
      }
      // url += `in-${keywordareaval}/`
    }

    var keywordareaval = $(".mysuggestform-wrapper .searchTxt").val()

    if (!_.isEmpty(selectedAreas)) {
      url += `in-dubai/in-${selectedAreas.map(area => area.slug).join("-and-")}/`
    } else if (keywordareaval) {
      url += `in-dubai/in-${JSON.parse(keywordareaval).toString()}/`
     
    } else if(searchText){
      url+=`in-dubai/in-${searchText?.replace(/ /g,"-")}/`;
    }
    else if(searchName){
      url += `in-dubai/in-${searchName?.replace(/ /g,"-")}/`
    }
    else {
      url += `in-dubai/`
    }

    navigate(url)
  }

  return (
    // <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
    <div className="search-box-wrap areas-list-comp">
      <div className="search-box-container">
        <div className="search-section">
          <div className="d-flex">
            <div className="btn-block-radio box_1">
              <input
                type="radio"
                name="buy-rent"
                value="buy"
                // onClick={e => setType("sale")}
                onChange={e => setType("sale")}
                checked={type === "sale"}
                id="radio-buy"
              />
              <label htmlFor="radio-buy">Buy</label>
            </div>
            {propertycat?.value !== "offplan" && (
              <div className="btn-block-radio box_2">
                <input
                  type="radio"
                  name="buy-rent"
                  value="rent"
                  // onChange={field.onChange}
                  // onClick={e => setType("rent")}
                  onChange={e => setType("rent")}
                  checked={type === "rent"}
                  // checked={field.value === "rent"}
                  id="radio-rent"
                />
                <label htmlFor="radio-rent">Rent</label>
              </div>
            )}
          </div>
          <div className="property-tyle">
            <Select
              options={type!=="rent"?propertyType:propertyType1}
              defaultValue={propertyType[0]}
              value={propertyType.value}
              name={"search_type"}
              placeholder={"Buy"}
              className={"select-control"}
              classNamePrefix={"react-select"}
              styles={customStyles}
              onChange={e => setPropertyType(e)}
              isSearchable={false}
              // components={{ DropdownIndicator: () => <i className={`${propertycat === true ? "icon icon-select-dropdown-primary" : ""}`}></i>, IndicatorSeparator: () => null }}
            />

            
            {propertycat?.value === "offplan" ? (
              <SearchBoxFilter areaVal={""} isBanner={true} handleSubmit={onSearch}/>
            ) : (
              <MultiSelectInput
                searchTypeVar={
                  type === "sale" ? "buy" : type === "rent" ? "rent" : ""
                }
                departmentVal={propertycat?.value}
                isBanner={true}
                areaVal={""}
                handleSubmit={onSearch}
              />
            )}
          </div>
          <button className="button button-filled-green" onClick={onSearch}>
            Search
          </button>
        </div>
      </div>
    </div>
    // </ScrollAnimation>
  )
}

export default SearchBox
