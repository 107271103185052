import loadable from "@loadable/component"
import { graphql } from "gatsby"
import React,{useState,useEffect} from "react"
import { Helmet } from "react-helmet"
// import Accordion from "../components/AccordianComponent/Accordion"
import HomeBanner from "../components/Banner/HomeBanner"
// import NewsSlider from "../components/NewsSlider/NewsSlider"
import Reviews from "../components/Reviews/Reviews"
// import ValuationModule from "../components/ValuationModule/ValuationModule"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { GetTransformedUrl } from "../common/stb-jamstack-ggfxclient/client"
import qs from "qs"
import { useLocation } from "@reach/router";
// import ResPageOffplanSlider from "../components/ResPageOffplanSlider/ResPageOffplanSlider"
import logoBlack from "../images/logo-white.svg"
import { removeDataAttributes } from "../components/Common/utils"
// import FeatureComponent from "../components/FeatureComponent/FeatureComponent"
import { Container } from "react-bootstrap"

const NewsSlider = loadable(()=>
  import("../components/NewsSlider/NewsSlider")
)
const ValuationModule = loadable(()=>
  import("../components/ValuationModule/ValuationModule")
)
const ResPageOffplanSlider = loadable(()=>
  import("../components/ResPageOffplanSlider/ResPageOffplanSlider")
)
const FeatureComponent = loadable(()=>
  import("../components/FeatureComponent/FeatureComponent")
)
const Accordion = loadable(()=>
  import("../components/AccordianComponent/Accordion")
)

const HomePageTemplate = ({ data, pageContext }, props) => {
  const [PageData, setPageData] = useState(data?.strapiPage)
  const [loading,setLoading] = useState(true)
  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)
  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false)

  const location = useLocation();
  const reviewData =
    data?.allStrapiSiteConfig?.edges?.length > 0 &&
    data?.allStrapiSiteConfig?.edges[0]

  let popUpData = ""

  let getIdList= null;
  if (PageData?.add_page_modules?.length > 0) {
    const results = PageData?.add_page_modules?.filter(
      item => (item.__typename === "STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES" || 
      item?.__component === "page-modules.custom-modules") &&
      item?.select_module === "property_slider"
    )
    if(results?.length){
      getIdList = results[0]?.propety_ids?.split(',')
    }
  }

  const filterOffplanItems  = data?.allNewdevelopments?.edges
  
  const offplanPropertyList = getIdList
  .map(id => filterOffplanItems.find(item => item?.node?.crm_id === id))
  .filter(Boolean);

  if (PageData?.add_page_modules?.length > 0) {
    const results = PageData?.add_page_modules?.filter(
       item => item.__typename === "STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT" || 
      item.__component === "components.popup-content"
    )
    if (results?.length > 0) {
      popUpData = results[0]
    }
  }
  var ldJson = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "haus & haus",
    alternateName: "haus & haus",
    url: "https://www.hausandhaus.com/",
    logo: "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/logo_white_schema_9fd27d4e15.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+971 4 302 5800",
      areaServed: "Dubai",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.7",
      reviewCount: "700",
    },
    sameAs: [
      "https://www.facebook.com/hausandhaus",
      "https://www.instagram.com/hausandhaus/",
      "https://www.linkedin.com/company/haus-&-haus/",
    ],
  }

  let scriptJson = {
    "@context": "https://schema.org",	
    "@type": "WebSite",	
    name: "haus & haus",	
    url: "https://www.hausandhaus.com/",
    potentialAction: [	
      {	
        "@type": "SearchAction",	
        target: `https://www.hausandhaus.com/properties/to-rent/{search_term_string}`,	
        "query-input": "required name=search_term_string"	
      },	
      {	
        "@type": "SearchAction",	
        target: "https://www.hausandhaus.com/properties/for-sale/{search_term_string}",	
        "query-input": "required name=search_term_string"	
      }	
    ],
    mainEntity: [	
      {	
        "@type": "WebPage",	
        name: "Rent",	
        url: "https://www.hausandhaus.com/properties/to-rent/in-dubai/"	
      },	
      {	
        "@type": "WebPage",	
        name: "Buy",	
        url: "https://www.hausandhaus.com/properties/for-sale/in-dubai/"	
      },	
      {	
        "@type": "WebPage",	
        name: "Off Plan",	
        url: "https://www.hausandhaus.com/off-plan/properties/for-sale/in-dubai/"	
      },	
      {	
        "@type": "WebPage",	
        name: "Commercial",	
        url: "https://www.hausandhaus.com/commercial-properties/for-sale/in-dubai/"	
      },	
      {	
        "@type": "WebPage",	
        name: "Contact",	
        url: "https://www.hausandhaus.com/contact/"	
      }	
    ]	
  
  }

  const query = qs.stringify(
    {
      populate: {
        ggfx_results:"*",
        choose_menu: "*",
        select_popular_search:"*",
        banner:{
          populate:{
            image:"*",
            cta_1_link:{
              populate:{
                parent:"*"
              }
            },
            cta_2_link:{
              populate:{
                parent:"*"
              }
            },
            cta_3_link:{
              populate:{
                parent:"*"
              }
            }
          }
        },
        add_page_modules: {
          on: {
            "page-modules.accordion":{
              populate:{
                add:{
                  populate:"*"
                }
              }
            },
            "page-modules.valuation-image":{
              populate:{
                cta_item:{
                  populate:{
                    link: "*",
                  }
                },
                add_image:{
                  populate:{
                    images: "*",
                  }
                }
              }
            },
            "components.review":{
              populate:{
                add_cta:{
                  populate:{
                    cta_link:"*"
                  }
                }
              }
            },
            "components.popup-content":{
              populate:{
                image: "*",
                cta: {
                  link:"*"
                }
              }
            },
            "page-modules.feature-block":{
              populate:{
                search_item:{
                  populate:{
                    image:"*"
                  }
                }
              }
            },
            "page-modules.custom-modules":{
              populate:{
                select_module:"*"
              }
            },
            "page-modules.news-selection":{
              populate:{
                news:{
                  populate:{
                    tile_image:"*",
                    ggfx_results:"*"
                  }
                }
              }
            }
          },
        },
      },
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("form") === "newsletter") {
      const footerElement = document.getElementById("newsletter-form");
      footerElement?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location.search]);

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const isPreview = urlParams.get('debug') === 'preview';
    const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/pages/${urlParams.get('strapi_id')}?${query}`

    // const apiUrl=process.env.GATSBY_STRAPI_SRC+'/api/events/'+urlParams.get('strapi_id')+'?populate[0]=modules&populate[1]=banner.cta_1_link&populate[2]=banner.cta_2_link&populate[3]=modules.icon_content&populate[4]=modules.icon_content.icon_module.image&populate[5]=form_content&populate[6]=ggfx_results&populate[12]=banner.image'
    const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

    if(isPreview){
    setIsPreviewEnabled(true)
    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        });
        const getData = await response.json();
        const previewData=removeDataAttributes(getData)
        setLoading(false)
        setEnablePreview(false)

        let myPrevData;
        if(PageData){
          myPrevData = PageData
        } else {
          myPrevData = {};
          myPrevData.banner.banner_image = {}
          myPrevData.add_page_modules=[]
          myPrevData.id = urlParams.get('strapi_id')
        }

        if(previewData){
          myPrevData.banner = previewData?.banner
          myPrevData.banner.image = previewData?.banner?.image
          myPrevData.banner.banner_content = previewData?.banner?.banner_content
          myPrevData.banner.banner_title = previewData?.banner?.banner_title
          myPrevData.banner.cta_1_title = previewData?.banner?.cta_1_title
          myPrevData.banner.cta_1_custom_link = previewData?.banner?.cta_1_custom_link
          myPrevData.banner.cta_1_link = previewData?.banner?.cta_1_link
          myPrevData.banner.cta_2_link = previewData?.banner?.cta_2_link
          myPrevData.banner.cta_2_title = previewData.banner.cta_2_title
          myPrevData.banner.cta_2_custom_link = previewData?.banner?.cta_2_custom_link
          myPrevData.banner.cta_3_custom_link = previewData?.banner?.cta_3_custom_link
          myPrevData.banner.cta_3_title = previewData?.banner?.cta_3_title
          myPrevData.imagetransforms = previewData?.imagetransforms
          myPrevData.title = previewData?.title
          myPrevData.layout = previewData?.layout
          myPrevData.pdf = previewData?.pdf
          myPrevData.select_popular_search = previewData.select_popular_search
          myPrevData.slug = previewData?.slug
          myPrevData.mobile_image = previewData?.mobile_image
          myPrevData.choose_menu = previewData?.choose_menu
          myPrevData.ggfx_results = previewData?.ggfx_results
          myPrevData.add_page_modules=previewData?.add_page_modules
          setPageData(PageData => ({ ...PageData, myPrevData }));
          sessionStorage.setItem("previewMeta",JSON.stringify(previewData?.seo))
        }
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }

    };
    fetchData();
    }
    else{
      setLoading(false)
      setIsPreviewEnabled(false)
      sessionStorage.removeItem("previewMeta")
    }

  },[])

  let urlParamsVal = ""
  let isPreviewVal = false

  if(typeof window !== "undefined"){
     urlParamsVal = new URLSearchParams(window.location.search);
    isPreviewVal = urlParamsVal.get('debug') === 'preview';
  }

 if(isPreviewVal){
  if(loading){
    return (
      <section className={"loader-wrapper"}>
        <div id="loader-wrapper">
          <div id="loader" class="new-loader">
            <img
              className="logo-white loader-logo"
              src={logoBlack}
              alt="logo"
            />
          </div>
          </div>
        </section>
      )
    }
  }

  if(enablePreview){
    return null
  }

  return (
    <Layout
      popUpData={popUpData}
      isPreviewEnabled={isPreviewEnabled}
      popularSearch={PageData?.select_popular_search?.title}
    >
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(ldJson, null, 2)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(scriptJson, null, 2)}
        </script>
        
      </Helmet>
      <HomeBanner
        mobileImage={PageData?.mobile_image}
        title={PageData.title}
        {...PageData.banner}
        id={PageData.strapi_id}
        pageData={PageData}
        isPreviewEnabled={isPreviewEnabled}

      />

      {PageData.add_page_modules?.map((item, i) => {
        return (
          <div key={item?.__typename}>
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_FEATURE_BLOCK"  ||
              item?.__component === "page-modules.feature-block")
              &&  (
              <FeatureComponent item={item} />
            )}
            {(item?.__typename === "STRAPI__COMPONENT_COMPONENTS_REVIEW" ||
              item?.__component === "components.review")
            && (
              <Reviews isPreviewEnabled={isPreviewEnabled} {...item} reviewData={reviewData} />
            )}
             {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES" || 
              item?.__component === "page-modules.custom-modules") &&
              item?.select_module === "property_slider" &&
              <Container>
                <ResPageOffplanSlider isPreviewEnabled={isPreviewEnabled} content={item?.content} titleText={item?.title}  isHomePage offplanPropertyList={offplanPropertyList} />
              </Container>
            }
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_NEWS_SELECTION" ||
              item?.__component === "page-modules.news-selection")
              && (
              <NewsSlider isPreviewEnabled={isPreviewEnabled} {...item} data={item?.news} pageName="homepage" />
            )}

            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_VALUATION_IMAGE" ||
              item?.__component ==="page-modules.valuation-image")
              && (
              <ValuationModule
                valuationData={item}
                imageData={item}
                PageData={PageData}
                isPreviewEnabled={isPreviewEnabled}
              />
            )}
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_ACCORDION" ||
              item?.__component === "page-modules.accordion")
              && (
              <Accordion
                add_toggle={item?.add}
                title="Why choose haus & haus"
                isPreviewEnabled={isPreviewEnabled}
              />
            )}
          </div>
        )
      })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage

  let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }

  const metaTitle = PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title

  const [preMetaTitle, setPreMetaTitle] = useState(metaTitle)

  let getImg= PageData?.banner?.image?.url? PageData?.banner?.image?.url:""
  let findImage = getImg?.substring(getImg?.indexOf("i"));

  let imageExtension=getImg?getImg?.split("."):""
  let ext=imageExtension?.length>0?imageExtension?.pop():""
  
  let imageUrl=PageData?.banner?.image?.url

    //new ggfx image
    const getImage =
    PageData?.banner?.image?.url
        ? PageData?.banner.image.url.replace(
            "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/",
            "",
          )
        : ""

    const filterImage =
      getImage && PageData?.ggfx_results?.length > 0
        ? PageData?.ggfx_results?.filter((item) => item.src_cftle === getImage)
        : ""

    // filter image with image size
    const getOGImg =
      filterImage?.length > 0 && filterImage[0]?.transforms?.length > 0
        ? filterImage[0].transforms.filter((item) => item.transform === "600x400"&&item.format===ext)
        : ""

 //to get image in uploaded format   
const ogRenderNew = ()=>{
  GetTransformedUrl({
    srcCftle: findImage,
    format: ext,
    transform:"600x400",
    id: PageData.strapi_id,
    field: "metaog",
    contentType: "page"
  })
}

useEffect(()=>{
  if(!getOGImg?.length>0)
      ogRenderNew()
},[]) 

//----

const pageImage=getOGImg?.length>0?getOGImg[0].url:imageUrl;

 //preview seo dynamic updates

 useEffect(() => {
  const timer = setTimeout(() => {
    if (typeof window !== "undefined" && isPreview) {
      try {
        const sessionMetaData = sessionStorage.getItem("previewMeta");
        if (sessionMetaData) {
          const parsedData = JSON.parse(sessionMetaData);
          if(parsedData?.metaTitle){
            setPreMetaTitle(parsedData?.metaTitle)
          }
        }
      } catch (error) {
        console.error("Error parsing session storage data:", error);
      }
    }
  }, 4000);

  // Clear the timeout if the component unmounts
  return () => clearTimeout(timer);
}, [isPreview]);

useEffect(() => {
  if(isPreview){
  document.title =  `${preMetaTitle} haus & haus`;
  }
}, [preMetaTitle]);

  return (
    <>
    <SEO
      title={(isPreview && preMetaTitle) ? preMetaTitle : metaTitle}
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
      imageUrl={pageImage}
    />
    </>
  )
}

export default HomePageTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...HomePageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_FEATURE_BLOCK {
          __typename
          ...FeatureBlockFragment
        }
        ... on STRAPI__COMPONENT_COMPONENTS_REVIEW {
          __typename
          ...ReviewFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
          __typename
          id
          add {
            content {
              data {
                content
              }
            }
            title
          }
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_NEWS_SELECTION {
          __typename
          id
          title
          news {
            tile_image {
              url
            }
            date
            imagetransforms {
              tile_image_Transforms
            }
            ggfx_results {
              src_cftle
              transforms {
                url
                transform
                format
              }
            }
            slug
            strapi_id
            read_time
            title
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_VALUATION_IMAGE {
          __typename
          id
          title
          content {
            data {
              content
            }
          }
          cta_item {
            link {
              slug
              strapi_parent {
                slug
              }
            }
            title
            content {
              data {
                content
              }
            }
          }
          add_image {
            images {
              url
            }
            id
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES {
          __typename
          select_module
          title
          propety_ids
          content {
            data {
              content
            }
          }
          add_cta_item {
            link {
              slug
            }
            title
            content {
              data {
                content
              }
            }
          }
        }
      }
    }

    allStrapiSiteConfig {
      edges {
        node {
          reviews_count {
            data {
              reviews_count
            }
          }
          star_rating
        }
      }
    }

    allNewdevelopments(
        filter: { publish: { eq: true } }
        sort: { fields: createdAt, order: DESC }
      ) {
        edges {
          node {
            slug
            strapi_id
            crm_id
            price
            createdAt
            whatsapp
            images {
                url
            }
            banner_title
            ggfx_results {
            src_cftle
              transforms {
                format
                url
                transform
              }
            }
            penthouse_bedroom
            apartment_bedroom
            townhouse_bedroom
            villa_bedroom
            area
            expected_completion_date
            developer
          }
        }
      }


    allStrapiBlog(
      filter: { publish: { eq: true } }
      sort: { fields: date, order: ASC }
    ) {
      edges {
        node {
          title
          slug
          video_url
          ggfx_results {
            src_cftle
            transforms {
              url
              transform
              format
            }
          }
          read_time
          date(formatString: "DD MMM yy")
          strapi_id
          tile_image {
            url
          }
          imagetransforms {
            tile_image_Transforms
          }
          blog_categories {
            blog_category
            slug
          }
        }
      }
    }
  }
`
